.body {
  padding: 10px;
  background: #efefef;
}
.body .wrapping {
  white-space: normal !important;
}

.body .carousel-container .item-prev, .carousel-container .item-next {
  background: rgba(47,48,49,1) !important;
}

.body .panel-title {
  margin-top: 15px;
}
.body .panel {
  background: white;
}
.body li {
  font-weight: normal !important;
}

.body li a {
  color: #888 !important;
}
.body .panel-body {
  padding-top: 10px !important;
}
.body .space {
  margin-top: 15px;
  margin-bottom: 5px;
}
.body .carousel, .carousel-container {
  min-height: 250px !important;
}

.img-responsive{
  object-fit: cover;
  width: 100%;
  height: 250px !important;
}

.timeline span {
  width: 100% !important;
  display: block;
}

.fa {
  font-size: 12px;
}
.fa.checked {
  color: #ffb700;
}

.btn.btn-add::before {
  content: "\271B" !important;
}
